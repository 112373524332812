import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import divider from 'assets/images/Line_topic.png';

import Group24 from '../../assets/images/topics/group-24.inline.svg';
import banner from '../../assets/images/topics/Innovation.jpg';
import Group27 from '../../assets/images/topics/group-27.inline.svg';
import Asset from '../../assets/images/topics/Asset.inline.svg';
import CTATopic from '../../components/CTATopic';

import speaker1 from '../../assets/images/topics/PeterHinssen.jpg';
import speaker2 from '../../assets/images/topics/PascalCoppens.jpg';

const Innovation = () => {
  return (
    <Layout>
      <Helmet>
        <title>EY - The enlightened session</title>
      </Helmet>
      <div className='topicTemplate'>
        <Container>
          <div className='topicIntro'>
            <div className='banner'>
              <img src={banner} alt='Innovation banner' />
            </div>
            <div className='text'>
              <Row>
                <Col>
                  <h1>Innovation</h1>

                  <div className='introText'>
                    <p>
                      Energy companies are on the cusp of a totally new wave of
                      innovation. Opportunities created by the latest
                      technologies will enable us to reimagine our tomorrow and
                      prepare for the day after tomorrow.
                    </p>
                    <p>
                      Reinventing decades-old organizations and creating new
                      business models to generate new revenue streams is not
                      easy. Many companies have tried. Success will come from
                      daring to fail and learning from mistakes (your own and
                      those made by others). Innovation, in this new era, will
                      be the product of greater collaboration and partnering
                      between talents to turn ideas into concepts and to scale
                      up at speed.
                    </p>
                  </div>
                </Col>
                <Col />
              </Row>
            </div>
          </div>
          <div className='divider'>
            <img src={divider} alt='divider' />
          </div>
          <div className='speakers'>
            <div className='speaker'>
              <div>
                <img src={speaker1} alt='Peter Hinssen' />
              </div>
              <div className='speakerIntro'>
                <h1>Peter Hinssen</h1>

                <div className='introText'>
                  <p>
                    Peter Hinssen, serial entrepreneur, Forbes contributor,
                    LinkedIn Influencer and best-selling author, is one of the
                    most sought-after thought leaders on organizing for the day
                    after tomorrow.
                  </p>
                </div>
              </div>
            </div>

            <div className='speaker'>
              <div>
                <img src={speaker2} alt='Pascal Coppens' />
              </div>
              <div className='speakerIntro'>
                <h1>Pascal Coppens</h1>
                <div className='introText'>
                  <p>
                    Pascal Coppens has lived and worked in China and Silicon
                    Valley for more than 20 years. A technology entrepreneur at
                    heart, Pascal is expert in all things to do with innovation
                    and preparing for the day after tomorrow.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='topicExpect'>
            <h1>
              What you <span>can expect?</span>
            </h1>
            <div>
              <p>
                The shift towards digitization has already begun and will
                accelerate further in the years to come. This enlightened
                session provides inspiration on how to innovate, how to measure
                your innovation successes, and how to scale up your innovation.
                Our keynote speakers will share real-life examples of how
                successful companies unlock their true potential when innovating
                at scale.
              </p>
            </div>
            <div className='expectWrapper'>
              <div className='expectItem'>
                <Asset className='asset' />
                <h6>The new age</h6>
                <p>
                  Organizations must manage the dilemma of staying competitive
                  in today’s market while preparing for the day after tomorrow.
                  We help you to imagine what the now, next and beyond will look
                  like for your company. And we explore the reasons why energy
                  companies should define their innovation agenda.
                </p>
                <p>
                  <em>Company-wide</em>
                </p>
              </div>
              <div className='expectItem'>
                <Group24 />
                <h6>Keynote session</h6>
                <p>
                  Inspiring speakers share their insightful vision of the day
                  after tomorrow and what innovation can bring to your company.
                  We consider alternative business models for energy companies.
                </p>
                <p>
                  <em>Company-wide</em>
                </p>
              </div>
              <div className='expectItem'>
                <Group27 />
                <h6>Interactive discussion</h6>
                <p>
                  In this interactive session, we identify your main innovation
                  challenges. We discuss how to define your vision, how to
                  execute your innovation agenda and how to measure your returns
                  from innovation.
                </p>
                <p>
                  <em>Limited audience</em>
                </p>
              </div>
            </div>
          </div>
          <CTATopic
            title={'Innovation'}
            description={
              'Join in this unique experience. Sign up for this exclusive enlightened session and rewrite your tomorrow and your day after tomorrow.'
            }
          />
        </Container>
      </div>
    </Layout>
  );
};
export default Innovation;
